.estate-type-button {
	width: 145px;
	height: 140px;
	border-radius: 20px;
	background: transparent;
	border: 1px solid var(--light-grey);
	margin-bottom: 30px;

	.icon {
		margin: 0px auto;

		svg {
			height: 90px;
			max-width: 115px;
			margin: auto;
		}

		&:last-child {
			svg {
				position: relative;
				top: 20px;
			}
		}
	}

	.title {
		width: 110px;
		text-align: center;
		font-size: 1rem;
		line-height: 18px;
		margin: auto;
		position: relative;
		top: -10px;

		span {
			color: var(--fridaa-blue);
			line-height: 18px;
		}
	}
}

/** DESKTOP **/
@media screen and (min-width: 769px) {
	.estate-type-button {
		width: 155px;
	}
	
	.title span {
		font-weight: 600;
		letter-spacing: 0.2px;
	}
}