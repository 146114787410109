.grouptype-selecor {
  width: 350px;
}

/** DESKTOP **/
@media screen and (min-width: 769px) {
  .grouptype-selecor {
    width: 460px;
    background-color: white;
    padding-top: 30px;
    border-radius: 5%;
  }
}
@media screen and (max-width: 480px) {
  .grouptype-selecor {
    width: 320px;
    background-color: white;
    padding-top: 30px;
    border-radius: 5%;
  }
}
