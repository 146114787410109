#homepage {
	height: 100vh;
	justify-content: flex-start;
	text-align: center;
	padding-top: 30px;

	.content {
		height: calc(100vh - 150px);
		z-index: 9;

		h1 {
			font-size: 2rem;
			line-height: 30px;
			margin: 0px 0px 5px;
		}

		.logo {
			// width: 125.46px;
				height: 42px;
			margin: 0px auto;
		}

		.intro {
			margin: 20px 0px 20px 0px;

			p {
				font-size: 1.125rem;
				line-height: 26px;
				margin: 0px auto;
				color: var(--fridaa-blue);
				width: 100%;

				span {
					font-family: "BalooDa2Bold";
				}
			}
		}

		.start-buttons {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.start-buttons-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				button {
					display: flex;
					flex-direction: row;
					background: #120136;
					backdrop-filter: blur(4px);
					width: 240px;
					height: 50px;
					border-radius: 31px;
					margin: 10px 10px;
					padding: 0px 8px 0px;
		
					span {
						color: white;
						font-weight: 600;
						line-height: 27px;
						text-align: center;
						letter-spacing: 0.2px;
						text-transform: uppercase;
						margin: auto;
						position: relative;
						left: 2px;
					}
		
					div {
						background: var(--fridaa-orange);
						width: 40px;
						height: 40px;
						border-radius: 50%;
						margin: auto 0px;
		
						img {
							width: 15px;
							height: 16px;
							margin: 0px auto;
						}
					}
				}
			}
		}
	}

	.ellipse {
		position: absolute;
		border-radius: 50%;
		background: #fff1ca;
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		justify-content: flex-start;
		padding-top: 10vh;

		.content {
			height: calc(100vh - 25vh);

			.start-buttons {
				.start-buttons-wrapper {
					flex-direction: column;
				}
			}

			h1 {
				font-size: 2.625rem;
				line-height: 49px;
				margin: 0px 0px 5px;
			}

			.logo {
				// width: 125.46px;
				height: 42px;
				margin: 0px auto;
			}

			.intro {
				margin: 20px 0px;

				p {
					width: 600px;
				}
			}
		}

		.ellipse {
			// bottom: -480px;
			// left: calc(50vw - 400px);
			// width: 800px;
			// height: 800px;
			display: none;
		}

		.illu svg {
			width: 700px;
			z-index: 2;
			position: absolute;
			bottom: 0px;
			left: calc(50vw - 700px / 2);
		}
	}

	/** MOBILES **/
	@media screen and (max-width: 480px) {
		.content {
			height: 70vh;
		}

		.ellipse {
			// position: fixed;
			// bottom: calc(-100vw + 75px + 50px);
			// left: calc(-75px / 2);
			// width: calc(100vw + 75px);
			// height: calc(100vw + 75px);
			display: none;
		}

		.illu svg {
			position: fixed;
			width: 90vw;
			height: 150px;
			z-index: 2;
			bottom: -3px;
			left: calc(50vw - 90vw / 2);
		}
	}
}