.onboarding-add-coborrower {
	border-radius: 20px;
	width: 206px;
	height: 126px;
	padding: 10px 10px 0px 10px;
  border: 2px dashed white;
	margin: 15px auto 10px;

	button {
		background: #fcbf1e;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		margin: 0px auto 5px;

		svg {
			margin: auto;
			width: 26px;
			height: 26px;
		}
	}

	.tips {
		height: 53px;
		width: 80%;
		margin: 5px auto 0px;

		span {
			color: white;
			font-family: "BalooDa2Bold";
			font-size: 1rem;
			text-align: center;
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		margin: auto;

		button {
			width: 45px;
			height: 45px;
			margin: 0px auto 5px;
	
			svg {
				margin: auto;
				width: 21px;
				height: 21px;
			}
		}

		.tips {
			height: auto;

			span {
				font-size: 1.063rem;
				letter-spacing: 0.6px;
			}
		}
	}

	/** IPHONE 8 */
	@media only screen and (device-width: 375px) {
		height: 110px;

		button {
			margin-top: -5px;
			width: 40px;
			height: 40px;
	
			svg {
				margin: auto;
				width: 20px;
				height: 20px;
			}
		}

		.tips {
			height: 53px;
			width: 80%;
			margin: 0px auto;
		}
	}
}
