#layout {
  .desktop-sidebar {
		width: 25vw;
	}
  main {
    z-index: 2;
    width: calc(100% - 25vw);
    min-height: 100vh;
    background: white;
  }

  /** MOBILES **/
	@media screen and (max-width: 480px) {
		.desktop-sidebar {
      display: none;
    }

    main {
      width: 100vw;
      height: -webkit-fill-available
    }
	}
}